@import url(https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&family=Ubuntu&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

a:hover {
	color: #282c34;
}

;

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.program-button {
	color: #fff !important;
	border-color: #fff !important;
	border-radius: 50px !important;
}



.program-button:hover {
	color: #468845 !important;
	border-color: #468845 !important;
	background-color: #fff !important;
}

.faq-button {
	padding: 0.5rem 3rem !important;
	font-size: 1.25rem;
	border-radius: 3rem !important;
	float: right;
}

.title-block {
	position: static;
	left: 371px;
	margin-top: 0;
	margin-right: 0;
	padding-right: 0;
	padding-left: 50px;
}

.footLink {
	color: black;
}

.footLink:hover {
	color: #468845 !important;
}

.socialBtn {
	color: #282c34;
	font-weight: 400;
	font-size: 20px;
}

.socialBtn :hover {
	color: #468845
}

.imgStyling {
	position: relative;
	border-radius: 50%;
	height: 12.5vw;
	width: 12.5vw;
	object-fit: cover;
	border: 5px solid #468845;
}

.rightNavbarflex {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rightNavbarflex h3 {
	padding-top: 7%;
	font-size: 3vw;
	display: none;
}

@media screen and (max-width: 1150px) {
	.faq-button {
		float: none;
		margin-top: 0;
	}
}

@media screen and (max-width: 400px) {
	.footLink {
		display: none;
	}
}
.d-block {
	display: block !important;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 20%;
}


.w-50 {
	width: 50% !important;
}
.alert-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.FaqBtn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
