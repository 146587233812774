.d-block {
	display: block !important;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: 20%;
}


.w-50 {
	width: 50% !important;
}