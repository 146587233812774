@import url("https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&family=Ubuntu&display=swap");

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

a:hover {
	color: #282c34;
}

;

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.program-button {
	color: #fff !important;
	border-color: #fff !important;
	border-radius: 50px !important;
}



.program-button:hover {
	color: #468845 !important;
	border-color: #468845 !important;
	background-color: #fff !important;
}

.faq-button {
	padding: 0.5rem 3rem !important;
	font-size: 1.25rem;
	border-radius: 3rem !important;
	float: right;
}

.title-block {
	position: static;
	left: 371px;
	margin-top: 0;
	margin-right: 0;
	padding-right: 0;
	padding-left: 50px;
}

.footLink {
	color: black;
}

.footLink:hover {
	color: #468845 !important;
}

.socialBtn {
	color: #282c34;
	font-weight: 400;
	font-size: 20px;
}

.socialBtn :hover {
	color: #468845
}

.imgStyling {
	position: relative;
	border-radius: 50%;
	height: 12.5vw;
	width: 12.5vw;
	object-fit: cover;
	border: 5px solid #468845;
}

.rightNavbarflex {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rightNavbarflex h3 {
	padding-top: 7%;
	font-size: 3vw;
	display: none;
}

@media screen and (max-width: 1150px) {
	.faq-button {
		float: none;
		margin-top: 0;
	}
}

@media screen and (max-width: 400px) {
	.footLink {
		display: none;
	}
}